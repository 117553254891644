<template>
    <div class="sampleLanguageContainer keepTogether" :style="{width: width > 0 ? width + 'px' : ''}">
        <div class="copyTextButton" v-on:click="copyTextToClipboard"><span v-html="copyText"></span></div>
        <div class="textbox" v-html="SampleTextWithHtmlBreaks"></div>
    </div>
</template>

<script>
import { translation } from '@/shared/translation';

export default {
    name: "SampleLanguageBox",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        client: {
            type: String,
            default() {
                return '';
            }
        },
        language: {
            type: String,
            default: 'English'
        }
    },
    computed: {
        width() {
            return this.options.width ?? 500;
        },
        SampleTextWithHtmlBreaks() {
            return this.SampleText.replace(/\n/g, '<br />');
        },
        SampleTextWithNoHtml() {
            return this.stripHtml(this.SampleText);
        },
        SampleText() {
            if(!this.options.textJson) return this.options.text;
            return translation.getTextForLanguage(this.options.textJson, this.language);
        },
        copyText() {
            if(!this.options.copyButtonTextJson) return 'COPY ME!';
            return translation.getTextForLanguage(this.options.copyButtonTextJson, this.language).replace(/\n/g, '<br />');
        }
    },
    methods: {
        fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            var successful = false;

            try {
                successful = document.execCommand('copy');
            } catch(err) {
                console.log(err);
            }

            const toastOptions = {
                position: 'top-right',
                duration: 1500
            }

            if(successful)
                this.$toast.success('Text Copied', toastOptions);
            else 
                this.$toast.error('Text Copy Failed', toastOptions);


            document.body.removeChild(textArea);
        },
        copyTextToClipboard() {
            const toastOptions = {
                position: 'top-right',
                duration: 1500
            }

            const plainText = this.SampleTextWithNoHtml;
            
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(plainText);
                return;
            }

            navigator.clipboard.writeText(plainText).then(() => {
                this.$toast.success('Text Copied', toastOptions);
            }, () => {
                this.$toast.error('Text Copy Failed', toastOptions);
            });
        },
        stripHtml(str) {
            const div = document.createElement('div');
            div.innerHTML = str;
            return div.textContent || div.innerText || '';
        }
    }
}
</script>