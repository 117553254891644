<template>
    <div class="barChartContainer keepTogether" v-if="barData_local.length" :style="{width: getWidth}">
        <div class="headerContainer">
            <div>{{header}} <span class="headerTotal">{{headerTotal}}</span></div>
        </div>
        <div class="subheaderContainer" v-html="subheader"></div>
        <div class="legendContainer" v-if="legend.length">
            <div class="legendRow" v-for="category in legend" :key="category.title">
                <div class="legendSquare" :style="{'background': category.color}"></div>
                <div class="legendCategory" v-html="category.title"></div>
            </div>
        </div>
        <div class="finePrintContainer" v-html="finePrint"></div>
        <div class="barChartArea">
            <div class="verticalLineBox" v-for="box in vertLineBoxes" :key="box.id" :style="{left: box.id % minorTicks === 0 ? box.left : ''}"></div>
            <div class="barChartBarContainer" v-for="bar in barData_local" :key="bar.sequence">
                <div v-if="bar.length > 0" class="barChartBar" :style="{'margin-left': bar.marginLeft, width: bar.width}">
                    <div class="barChartSegmentGroup" v-for="segmentGroup in bar.segmentGroups" :key="segmentGroup.sequence" :style="{width: segmentGroup.width}">
                        <div class="barChartSegment" v-for="segment in segmentGroup.segments" :key="segment.sequence" :style="{width: segment.width}">
                            <div class="barChartSegmentBar" :style="{'background': segment.color}"></div>
                        </div>
                        <div class="barChartSegmentTitle" v-if="!isTransparent(segmentGroup)"><span v-html="segmentGroup.title"></span></div>
                    </div>
                </div>
            </div>
            <div v-if="measuringTapeIsVisibile" :style="{width: measuringTape.width, 'margin-left': measuringTape.left}">
                <div class="measuringTape" :style="{'border-color': (measuringTape.tapeColor)}">
                    <div class="measuringTapeLine" :style="{'border-color': (measuringTape.tapeColor)}"></div>
                    <div class="measuringTapeText" :style="{color: (measuringTape.tapeColor), 'text-align': measuringTape.alignment }"><span v-html="measuringTape.message"></span></div>
                </div>
            </div>
        </div>
        <div class="xAxisContainer">
            <div class="xAxisTickContainer">
                <div class="xAxisTicks" v-for="box in vertLineBoxes" :key="box.id" :class="[box.id < 10 ? 'singleCharXAxis' : '']" :style="{left: box.left}">
                    <span v-if="box.id === 0" v-html="xAxisLabel"></span>
                    <span v-else-if="box.id % majorTicks === 0">{{Math.floor(minValue + (box.id * (maxValue - minValue) / totalTicks))}}</span>
                </div>                                                                                                             
                <div class="xAxisMaxValue" :class="maxValueCssClass" v-html="maxValue"></div>
            </div>
        </div> 
    </div>
</template>


<script>
import { useLoaStore } from "@/stores/loa";
import { replacementStrings } from '@/shared/replacementStrings';

export default {
    name: "BarChart",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        client: {
            type: String,
            default() {
                return '';
            }
        }
    },
    setup() {
      const piniaSurvey = useLoaStore();
      return {
        piniaSurvey
      };
    },
    data() {
        return {
            vertLineBoxes: [],
            totalTicks: 0,
            width: 600,
            legend: [],
            header: '',
            headerTotal: '',
            subheader: '',
            xAxisLabel: '',
            barData: [],
            majorTicks: 5,   // Total number of major ticks to display
            minorTicks: 4,   // Number of minor ticks to display between each major tick
            minValue: 0,
            maxValue: 100,
            finePrint: '',
            measuringTape: {},
            attributes: {},
            replacementStrings: {}
        }
    },
    async mounted() {
        this.attributes = this.piniaSurvey.getAttributes;
        this.replacementStrings = this.piniaSurvey.getReplacementStrings;

        if(this.options.legend && this.options.legend.length > 1)
            this.legend = this.options.legend;
        
        if(this.options.width)
            this.width = this.options.width;

        if(this.options.header)
            this.header = this.options.header;

        if(this.options.subheader)
            this.subheader = this.populateRuntimeProperty(this.options.subheader);

        if(this.options.headerTotal)
            this.headerTotal = this.options.headerTotal;

        if(this.options.xAxisLabel)
            this.xAxisLabel = this.options.xAxisLabel;

        if(this.options.barData)
            this.barData = this.options.barData;

        if(this.options.majorTicks)
            this.majorTicks = this.options.majorTicks;

        if(this.options.minorTicks)
            this.minorTicks = this.options.minorTicks;

        if(this.options.minValue)
            this.minValue = this.options.minValue;

        if(this.options.maxValue)
            this.maxValue = this.options.maxValue;

        if(this.options.finePrint)
            this.finePrint = this.populateRuntimeProperty(this.options.finePrint);

        this.measuringTape.visible = false;

        if(this.options.measuringTape)
        {
            this.measuringTape.startValue = this.populateRuntimeProperty(this.options.measuringTape.startValue ?? this.minValue);
            this.measuringTape.endValue = this.populateRuntimeProperty(this.options.measuringTape.endValue ?? this.maxValue - 1);
            this.measuringTape.tapeColor = this.options.measuringTape.htmlColor ? this.options.measuringTape.htmlColor : "#000000";
            this.measuringTape.alignment = this.options.measuringTape.messageAlignment ? this.options.measuringTape.messageAlignment : "left";
            this.measuringTape.width = ((this.measuringTape.endValue - this.measuringTape.startValue) /(this.maxValue - this.minValue)) * 100 + "%";
            this.measuringTape.left = ((this.measuringTape.startValue - this.minValue) / this.maxValue) * 100 + "%";
            if(this.options.measuringTape.message)
                this.measuringTape.message = this.options.measuringTape.message;
            else 
                this.measuringTape.message = this.maxValue + " " + this.xAxisLabel +": Your Job is Protected";
        }  
       
        this.totalTicks = (this.maxValue - this.minValue);
        for (let i = 0; i < this.totalTicks; i++) {
            this.vertLineBoxes.push({
                id: i,
                left: (i / this.totalTicks * 100) + '%'
            });
        }
    },
    computed: {
        maxValueCssClass() {
            if(this.maxValue < 10) return "xAxisOneCharMaxValue";
            else if (this.maxValue < 99) return "xAxisTwoCharMaxValue";
            else return "xAxisThreeCharMaxValue";
        },
        barData_local() {
            var myBarData = this.barData;

            myBarData.sort((a,b) => {return a.sequence - b.sequence});
            myBarData.forEach(bar => {
                bar.segmentGroups.sort((a,b) => {return a.sequence - b.sequence});

                // Populate any attribute-based fields first
                bar.segmentGroups.forEach(group => {
                    group.title = this.populateRuntimeProperty(group.title);
                    group.segments.forEach(seg => {
                        seg.startValue = this.populateRuntimeProperty(seg.startValue);
                        seg.endValue = this.populateRuntimeProperty(seg.endValue);
                        seg.color = this.populateRuntimeProperty(seg.color);
                    })
                })
                
                bar.segmentGroups.forEach(group => {
                    group.segments.sort((a,b) => {return a.sequence - b.sequence});
                    group.length = group.segments.reduce((groupTotal, segment) => {
                        return groupTotal += (segment.endValue - segment.startValue);
                    }, 0);
                })
                bar.length = bar.segmentGroups.reduce((barTotal, segmentGroup) => {
                    return barTotal += segmentGroup.length;
                }, 0);
                bar.width = (bar.length / (this.maxValue - this.minValue)) * 100 + '%';
                bar.marginLeft = (bar.segmentGroups[0].segments[0].startValue - this.minValue) / (this.maxValue - this.minValue) * 100 + '%';
                bar.segmentGroups.forEach(group => {
                    group.width = group.length / bar.length * 100 + '%';
                    group.segments.forEach(segment => {
                        segment.width = (segment.endValue - segment.startValue) / group.length * 100 + '%';
                    });
                });

            });

            return myBarData;
        },
        getWidth() {
            return isNaN(this.width) ? this.width : this.width + 'px';
        },
        measuringTapeIsVisibile() {
            if(this.attributes["BAR_CHART_TAPE_VISIBLE"] === "NO" || this.measuringTape.startValue === this.measuringTape.endValue)
                return false;
            else if(this.attributes["BAR_CHART_TAPE_VISIBLE"] === "YES")
                return true;

            return this.options.measuringTape?.visible;
        }
    },
    methods: {
        isTransparent: function(segGroup) {
            return (segGroup.segments.length === 1 && segGroup.segments[0].color.toLowerCase() === 'transparent');
        },
        populateRuntimeProperty: function(sourceProperty) {
            if(Object.hasOwn(this.attributes, sourceProperty))
                return this.attributes[sourceProperty];
            if(Object.hasOwn(this.replacementStrings, '%' + sourceProperty + '%'))
                return replacementStrings.replaceStringsAllLanguages(sourceProperty, this.replacementStrings);

            return sourceProperty;
        }
    }
}
</script>
<style lang="scss" scoped>
.measuringTapeText {
    padding: 0px 3px;
}

.measuringTapeLine, .measuringTapeText, .barChartSegmentTitle span {
    position: relative; 
    z-index: 10;
}

.measuringTape {
    height: 30px;
    border-right: solid  3px;
    border-left: solid 3px;
    margin-bottom: 20px;
}

.measuringTapeLine {
    width: 100%;
    height: 15px;
    border-bottom: solid 3px;
}

.measuringTapeText {
    margin-top: 5px;
    font-weight: normal;
    font-size: var(--bar-chart-measuring-tape-text-size);
    line-height: var(--bar-chart-measuring-tape-line-height);
    font-family: var(--bar-chart-font-family);
    text-transform: uppercase;
    span {
        background-color: var(--bar-chart-measuring-tape-background-color);
    }
}

.xAxisTickContainer {
    left: -5px;
}

.singleCharXAxis {
    padding-left: 2px;
}

</style>