<template>
    <BarChart v-if="barchart" :options="barchart" :key="key"></BarChart>
</template>

<script>
import BarChart from '@/components/shared/BarChart';
import { useLoaStore } from "@/stores/loa";
import { data } from '@/shared/data';

export default {
    name: 'TestBarChart',
    components: { BarChart },
    setup() {
      const piniaSurvey = useLoaStore();
      return {
        piniaSurvey
      };
    },
    data() {
        return {
            key: 1,
            barchart: null,
            attributes: [
                {
                    code: 'LEAVE_LENGTH',
                    value: 10
                },
                {
                    code: 'LOA_EST_LEAVE_LENGTH',
                    value: 5
                },
                {
                    code: 'BAR_CHART_TAPE_VISIBLE',
                    value: 'NO'
                },
                {
                    code: 'BAR_CHART_COLOR_PTO',
                    value: 'blue'
                },
                {
                    code: 'LOA_TYPE',
                    value: 'PTO'
                }
            ]
        }
    },
    async mounted() {
        const resp = await data.getMediaProperty(544);
        let attributes = this.piniaSurvey.getAttributes;
        this.attributes.forEach(attr => {
            attributes[attr.code] = attr.value;
        })
        this.piniaSurvey.setAttributes(attributes);

        this.barchart = resp.configuration;
    }
}
</script>

<style scoped lang="scss" src="@/assets/css/client/loa/bar_chart.scss"></style>