<template>
     <div class="page" v-if="showPage">
        <div v-html="getTextForLanguage(page.headerTextJson)" class="survey-page-title-info"></div>
        <Question v-for="(ques, ind) in visibleQuestions" :key="ques.id" :question="ques.questionInfo" :onSetAnswer="setAnswer"
                :showButtonsWithQuestions="showButtonsWithQuestions" :showInlineContinueButton="showInlineContinueButton(ind)" 
                :continueButtonText="getTextForLanguage(page.configuration.continueButtonConfig.textJson)" :submitPage="submitPage"
                :onVideoFinish="onVideoFinish_local" :language="language" :storageBaseUrl="storageBaseUrl"></Question>
        <div class="action-button-container">
            <button class="action-button back-button" v-if="page.configuration.backButtonConfig.visible" @click="gotoPreviousPage"
                v-html="getTextForLanguage(page.configuration.backButtonConfig.textJson)"></button>
            <button class="action-button submit-button" v-if="page.configuration.continueButtonConfig.visible && showContinueButton" 
                v-html="getTextForLanguage(page.configuration.continueButtonConfig.textJson)" @click="submitPage" :disabled="!continueButtonEnabled"></button>
            <button class="action-button skip-button"  v-if="page.configuration.skipButtonConfig.visible && !continueButtonEnabled" 
                v-html="getTextForLanguage(page.configuration.skipButtonConfig.textJson)" @click="submitPage"></button>
        </div>
    </div>
</template>

<script>
import Question from '@/components/shared/Questions/Question';
import { useLoaStore } from "@/stores/loa";
import { data } from '@/shared/data';
import jsonLogic from 'json-logic-js';
import { translation } from '@/shared/translation';

export default {
    name: 'SurveyPage',
    components: { Question },
    props: {
        page: {
            type: Object,
            required: true
        },
        showButtonsWithQuestions: {
            type: Boolean
        },
        onVideoFinish: {
            type: Function
        },
        onQuestionAnswered: {
            type: Function
        },
        onValidateAnswer: {
            type: Function
        },
        // Allows processing of Survey Actions such as PopupModalWindow
        beforePageSubmit: {
            type: Function
        },
        // Perform calculations and transition to next page
        onPageSubmit: {
            type: Function
        },
        // { name: 'English', code: 'en' }
        language: Object,
        storageBaseUrl: {
            type: String
        },
        showContinueButton: {
            type: Boolean,
            default: false
        },
        skipPage: {
            type: Function,
            required: true
        },
        onGoToPreviousPage: {
            type: Function 
        }
    },
    setup() {
      const piniaSurvey = useLoaStore();
      return {
        piniaSurvey
      };
    },
    data() {
        return {
            questions: [],
            showPage: false,
            continueButtonEnabled: true
        }
    },
    computed: {
        attributes() {
            return this.piniaSurvey.getAttributes;
        },
        visibleQuestions() {
            if(!this.questions.length) return [];
            return this.questions.filter(search => search.visible);
        }
    },
    mounted() {
        this.loadPage();
    },
    methods: {
        loadPage() {
            if(this.page.questions == null || this.page.questions.length === 0) {
                this.questions = [];
            }
            else 
                this.checkQuestions();

            this.page.questions.forEach((ques) => {
                if(ques.questionInfo.attribute && !Object.hasOwn(this.attributes, ques.questionInfo.attribute.code)) {
                    this.piniaSurvey.setAttribute(ques.questionInfo.attribute.code, null);
                }

                if(ques.questionInfo.answers) {
                    ques.questionInfo.answers.sort((a,b) => {
                        return a.sequence - b.sequence;
                    });
                }
            });

            this.showPage = true;
        },
        async checkQuestions() {
            if(this.page.questions == null) return;

            let disableContinueButton = false;

            let newQuestions = [];

            for(let i = 0; i < this.page.questions.length; i++)
            {
                let question = this.page.questions[i];
                if(this.attributes == null)
                    question.visible = true;
                else if(question.conditionalJson && question.conditionalJson != '{}' ) {
                    let result =  jsonLogic.apply(JSON.parse(question.conditionalJson), this.attributes);
                    if(result != question.visible) {
                        question.visible = result;
                    }
                } 
                else 
                    question.visible = true;

                newQuestions.push(question);

                if(question.questionInfo.mediaPropertyId && 
                    question.questionInfo.mediaPropertyId > 0 &&
                    question.questionInfo.mediaPropertyInfo.mediaType == 'Video')
                        disableContinueButton = true;
            }

            if(newQuestions.filter(search => search.visible).length == 0) {
                if(this.skipPage) await this.skipPage(); 
            }   
            else {
                if(this.page.finishedVideo)
                    this.continueButtonEnabled = true;
                else
                    this.continueButtonEnabled = !disableContinueButton;

                this.questions = newQuestions;
            }
        },
        setAnswer: async function(code, answerValue, valueSelectLabel = null) {
            //allow survey answers to be put into replacement strings as well
            var useAsReplacementString = this.page.questions.find(search => search.questionInfo.attribute.useAsReplacementString === true)?.questionInfo.attribute.useAsReplacementString || false;
            if(useAsReplacementString) {
                let replacementStrings = this.piniaSurvey.getReplacementStrings;
                replacementStrings['%' + code + '%'] = answerValue;
                replacementStrings['%' + code + "_LABEL%"] = valueSelectLabel;
                this.piniaSurvey.setReplacementStrings(replacementStrings);
            } 

            this.piniaSurvey.setAttribute(code, answerValue);
            if(valueSelectLabel)
               this.piniaSurvey.setAttribute(code + "_LABEL", valueSelectLabel);

            if(this.isDebug)
                console.log("setAnswer: " + JSON.stringify(this.attributes));
            if(this.onQuestionAnswered)
                await this.onQuestionAnswered();
            await this.checkQuestions();
            this.checkToSubmitPage();
        },
        checkToSubmitPage: function() {
            // The parent component can override page submission to perform SurveyAction instead
            let allowSubmit = true;
            if(this.beforePageSubmit)
                allowSubmit = this.beforePageSubmit();

            if(allowSubmit && this.visibleQuestions.length == 1 && !this.page.configuration.continueButtonConfig.visible ) {
                this.submitPage();
                return;
            }
        },
        submitPage: async function() {
            let question; 
            let code;
            let answer;

            for(let i = 0; i < this.visibleQuestions.length; i++)
            {
                question = this.visibleQuestions[i]; 

                if(!question.questionInfo.attribute || !question.questionInfo.attribute.code) continue;
                if(question.requiredTF == false) continue;

                code = question.questionInfo.attribute.code;
                answer = this.attributes[code];

                if(answer == null || answer === '') {
                    this.$toast.error('You must answer every question before continuing', );
                    return;
                }
                else if(this.onValidateAnswer) {
                    var retVal = await this.onValidateAnswer(code, answer);
                    if(retVal.success === false) {
                        this.$toast.error(retVal.error_message, );
                        return;
                    }
                }                
            } 

            //do this separately so no logging until we know all questions are answered
            for(let i = 0; i < this.visibleQuestions.length; i++)
            {
                question = this.visibleQuestions[i]; 
                if(!question.logActivityTypeCode) continue;

                if(!question.questionInfo.attribute || !question.questionInfo.attribute.code) continue;
                code = question.questionInfo.attribute.code;
                answer = this.attributes[code];
                
                let answerText = '';
                let answerIndex = !question.questionInfo.answers || Array.isArray(answer) ? -1 : question.questionInfo.answers.findIndex(search => search.answerValue === answer);
                if(answerIndex >= 0)
                    answerText = question.questionInfo.answers[answerIndex].answerText;

                else if (Array.isArray(answer)) {
                    answerText = [];
                    for(let j = 0; j < answer.length; j++) {
                        answerIndex = question.questionInfo.answers.findIndex(search => search.answerValue === answer[j]);
                        if(answerIndex >= 0) 
                            answerText.push(question.questionInfo.answers[answerIndex].answerText);
                    }
                } 

                let loggingObject = { 
                    questionCode: question.questionInfo.attribute.code,
                    answerText: answerText,
                    answerValue: answer,
                    questionText: question.questionInfo.questionText,
                    questionKey: question.id,
                    language: this.language.name
                };

                if(question.logActivityTypeCode) {
                    data.postActivityLog({
                        logActivityTypeCode: question.logActivityTypeCode,
                        data: JSON.stringify(loggingObject)
                    });
                }
            }

            if(this.isDebug)
                console.log(this.attributes);

            if(this.onPageSubmit)
             {
                //this ensures any answer values are saved as a number, rather than string
                for (const key of Object.keys(this.attributes)) {
                    if(this.attributes[key] != null && !isNaN(this.attributes[key]))
                        this.piniaSurvey.setAttribute(key,  Number(this.attributes[key]));
                }

                await this.onPageSubmit(this.page);
             }
        },
        // Add the pageID to simplify code in Survey component
        onVideoFinish_local: function(questionId) {
            this.onVideoFinish(questionId, this.page.id);
        },
        getTextForLanguage: function(jsonField) {
            return translation.getTextForLanguage(jsonField, this.language.name);
        },
        showInlineContinueButton: function(index) {
            return this.page.configuration.continueButtonConfig.visible 
                    && this.continueButtonEnabled 
                    && index === (this.visibleQuestions.length - 1);
        },
        gotoPreviousPage: function() {
            if(this.onGoToPreviousPage) this.onGoToPreviousPage();
        }
    },
    watch: {
        page() {
            this.loadPage();
        }
    }
}
</script>
