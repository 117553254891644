import videos from '@/components/Carnival/videos';
import { routes as loaRoutes } from '@/router/loaRouter';

export const routes = [
  ...loaRoutes, 
  {
    path: '/videos',
    redirect: '/videos/RxPriceShop'
  },
  {
    path: '/videos/:videoName',
    name: 'Default',
    component: videos,
    meta: {
      title: 'Carnival Videos'
    }
  }
];
