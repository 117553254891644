<template>
    <div>
        <div class="homePage">
            <div class="header noprint">
                <div class="logo">
                    <img :src="logoSrc" />
                </div>
            </div>
            <AccordionSectionView v-if="accordionCode"
                                    :accordionCode="accordionCode"
                                    :feedbackQuestions="feedbackQuestions"></AccordionSectionView>
            <div class="footer_container noprint" >
                <div class="footer">
                    <p class="copyright">
                        <span>©{{year}} The Jellyvision Lab, Inc.All rights reserved.<br/></span>
                        <a href="https://www.jellyvision.com/tos/" target="_blank">Terms of Service</a>
                        <a href="https://www.jellyvision.com/privacy/" target="_blank">Privacy Policy</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccordionSectionView from '@/components/shared/AccordionSectionView';
import { data } from "@/shared";
import { translation } from "@/shared/translation";

export default {
    name: 'SinglePageAccordion',
    components: { AccordionSectionView },
    props: {
        feedbackQuestions: Array
    },
    data() {
        return {
            accordionCode: "",
            logoSrc: ''
        }
    },
    computed: {
        year() { 
            return new Date().getFullYear();
        }
    },
    async mounted() {
        let values = await data.getClientConstants();
        this.accordionCode = translation.getTextForLanguage(values.find(val => val.key === 'ACCORDION_CODE').valueJson, 'English');
        
        // Get logo, but don't break if not there
        let logo;
        const logoMpId = await data.getClientLogoAssetID();
        if(logoMpId)
            logo = await data.getMediaProperty(logoMpId);
        if(logo)
            this.logoSrc = logo.configuration.src;
    }
}
</script>