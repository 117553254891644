<template>
    <div class="cl-container keepTogether">
        <div class="cl-background-container" > 
            <img v-if="topImageLink" :src="topImageLink" class="cl-background-img cl-top-img"  />
            <img v-if="middleImageLink" :src="middleImageLink" class="cl-background-immg cl-middle-img" />
            <img v-if="bottomImageLink" :src="bottomImageLink" class="cl-background-img cl-bottom-img" />
        </div>
        <div class="cl-content-container">
            <div class="cl-header" v-if="this.configuration.headerTextJson" v-html="getTextForLanguage(this.configuration.headerTextJson)"></div>
            <div class="cl-data-container">
                <div class="cl-data-row-container">
                    <div v-for="item in configuration.items" :key="item.id" class="cl-data-row" v-on:click="checkboxClick(item)" 
                            :class="configuration.selectable ? 'cl-data-row-selectable' : ''">
                        <div class="cl-checkbox">
                            <img src="/images/Checklist/LOA-Checklist-Box.png" v-if="!item.checked" />
                            <img src="/images/Checklist/LOA-Checklist-Box_with_Check.png" v-if="item.checked" />
                        </div>    
                        <div class="cl-checkbox-label" v-html="getTextForLanguage(item.textJson)"></div>
                    </div>
                </div>
            </div>
            <div class="cl-footer" v-if="this.configuration.footerTextJson" 
                v-html="getTextForLanguage(this.configuration.footerTextJson)"></div>
        </div>
    </div>
</template>

<script>
import { translation } from '@/shared/translation';
import { data } from '@/shared';

export default {

    name: "Checklist",
    props: {
        configuration: {
            type: Object,
            default() {
                return {};
            }
        },
        client: {
            type: String,
            default() {
                return '';
            }
        },
        language: {
            type: String,
            default: 'English'
        }
    },
    data() {
        return {
            cursor: 'default',
            storageBaseUrl: null
        }
    },
    computed: {
        topImageLink() {
            if(!this.storageBaseUrl || !this.configuration.images?.top)
                return null;
            else 
                return this.storageBaseUrl + this.configuration.images?.top;
        },
        middleImageLink() {
            if(!this.storageBaseUrl || !this.configuration.images?.middle)
                return null;
            else 
                return this.storageBaseUrl + this.configuration.images?.middle;
        },
        bottomImageLink() {
            if(!this.storageBaseUrl || !this.configuration.images?.bottom)
                return null;
            else 
                return this.storageBaseUrl + this.configuration.images?.bottom;
        },
    },
    async mounted() {
        this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
    },
    methods: {
        checkboxClick: function(item) {
            if(!this.configuration.selectable)
                return;
            if(item.checked)
                item.checked = false;
            else
                item.checked = true;
        },
        getTextForLanguage(jsonField) {
            return translation.getTextForLanguage(jsonField, this.language);
        }
    }
}
</script>
<style lang="scss" scoped src="@/assets/css/shared/checklist/checklist_basic.scss"></style>
