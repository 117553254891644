<script>
import { h } from 'vue';
import ToolTip from '@/components/shared/MediaProperties/ToolTip2';
import { translation } from '@/shared/translation';
import { replacementStrings } from '@/shared/replacementStrings';

export default {
    props: {
        cssClass: {
            type: String,
            default: null
        },
        displayText_multiLang: {
            type: Array
        },
        language: {
            type: String,
            default: "English"
        },
        tooltipConfiguration: {
            type: Object,
            default: () => ({})
        },
        replacements: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        parsedContent() {
            if (!this.displayText_multiLang) return [];

            const regex = /<ToolTip>(.*?)<\/ToolTip>/g;
            const parts = [];
            let lastIndex = 0;
            let match;


            var displayText = this.displayText_multiLang;
            
            if(this.replacements) {
                displayText =  replacementStrings.replaceStringsAllLanguages(displayText, this.replacements);
            }
            
            displayText = translation.getTextForLanguage(displayText, this.language) || '';
            
            while ((match = regex.exec(displayText)) !== null) {
                // Add plain text before the tooltip
                if (match.index > lastIndex) {
                    parts.push(h('span', { innerHTML: displayText.substring(lastIndex, match.index) }));
                }

                // Add Tooltip component dynamically
                parts.push(this.createTooltipComponent(match[1]));

                lastIndex = regex.lastIndex;
            }

            // Add remaining text after the last tooltip
            if (lastIndex < displayText.length) {
                parts.push(h('span', { innerHTML: displayText.substring(lastIndex) }));
            }

            return parts;
        }
    },
    methods: {
        createTooltipComponent(text) {
            return h(ToolTip, {
                configuration: this.tooltipConfiguration
            }, {
                hoverText: () => h('span', { innerHTML: text} ),
                content: () => h('span', { innerHTML: translation.getTextForLanguage(this.tooltipConfiguration.textJson, 
                        this.language)}),
                hoverIcon:
                    this.tooltipConfiguration.hoverIcon ?
                        () => h('img', { src: this.tooltipConfiguration.hoverIcon }) : undefined
            });
        }
    },
    render() {
        const validTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
        const tag = validTags.includes(this.cssClass) ? this.cssClass : 'div';
        const className = validTags.includes(this.cssClass) ? undefined : this.cssClass;

        if(this.parsedContent.length > 1)
            return h(tag, { class: className }, this.parsedContent);
        else {
            const singleContent = this.parsedContent[0];
            if(singleContent)
                return h(tag, { class: className, innerHTML: singleContent.props?.innerHTML} );
            else 
                return null;
        }
    }
};
</script>