<template>
  <div class="flex_across">
    <div class="homePage" v-if="page >= 0">
      <div class="header noprint">
        <div class="logo">
          <img
            v-if="loaConfiguration.webPageLogoPath"
            :src="loaConfiguration.webPageLogoPath"
          />
        </div>
        <LoaContactModal
          v-if="showModal"
          :onClose="closeModal"
          :phoneNumber="loaConfiguration.contactModalPhoneNumber"
          :emailAddress="loaConfiguration.contactModalEmailAddress"
          :modalTitle="loaConfiguration.contactModalTitle"
          :modalText="loaConfiguration.contactModalMessage"
          :customContactInfo="loaConfiguration.contactModalCustomContactInfo"
          :footer="loaConfiguration.contactModalFooter"
          componentKey="i"
        ></LoaContactModal>
        <div
          class="contact"
          @click="ShowContactModal()"
          v-html="contactModalButtonText"
        ></div>
      </div>
      <div
        class="titleBar noprint"
        :class="subHeaderClasses"
        :style="getSubHeaderStyle"
      >
        <div class="title" :class="page === 0 ? 'visible' : 'hidden'">
          Leave of Absence
        </div>
        <div class="subtitle" :class="page == 0 ? 'visible' : 'hidden'">
          Planning Portal
        </div>
      </div>
      <div class="arrowBar noprint" :class="page > 0 ? 'visible' : 'hidden'">
        <div
          v-if="page === 3 && activeInfoPageConfig"
          class="left-of-arrow desktop_only"
        ></div>
        <div
          class="arrow-down"
          :class="getArrowClass"
          :style="getArrowColor"
        ></div>
        <div
          v-if="page === 3 && activeInfoPageConfig"
          class="right-of-arrow desktop_only"
        ></div>
      </div>
      <div class="content">
        <LoaFrontPage
          v-if="page === 0"
          :mainText="loaConfiguration.frontPageMainText"
          :onClickStart="getStarted"
          :onManagerClick="onManagerClick"
          :finePrintColumn1="loaConfiguration.finePrintColumn1"
          :finePrintColumn2="loaConfiguration.finePrintColumn2"
          :finePrintColumn3="loaConfiguration.finePrintColumn3"
          :managerText="loaConfiguration.managerText"
          :title="loaConfiguration.frontPageTitle"
          :subtitle="loaConfiguration.frontPageSubtitle"
          :rightContent="loaConfiguration.frontPageRightContent"
        ></LoaFrontPage>
        <LoaSurveyIntro
          v-if="page === 1"
          :continueFn="goToSurvey"
          :backFn="previousPage"
          :title="loaConfiguration.surveyIntroTitle"
          :paragraphs="loaConfiguration.surveyIntroText"
          :managerText="loaConfiguration.managerText"
          :rightContent="loaConfiguration.introPageRightContent"
          :backText="backText ? backText : null"
        ></LoaSurveyIntro>
        <Survey3
          v-if="page == 2"
          :code="surveyCode"
          sectionTitle="Eligibility"
          :onEndOfSurvey="local_onEndOfSurvey"
          :onGoBack="previousPage"
          goBackButtonText="Go Back"
        >
        </Survey3>
        <LoaInformationPage
          v-if="page == 3 && activeInfoPageConfig"
          :corporateLogoLocation="loaConfiguration.emailCorporateLogoPath"
          :emailOpenModalText_multiLang="emailOpenModalText_multiLang"
          :config="activeInfoPageConfig"
          :onClickStartOver="StartOver"
          :emailTemplate="emailTemplate"
          :replacements="replacements"
        ></LoaInformationPage>
        <LoaRedirectPage
          v-if="page == 3 && activeRedirectText"
          :onClickStartOver="StartOver"
          :text="activeRedirectText"
          :replacementStrings="replacements"
        ></LoaRedirectPage>
      </div>
      <div class="footer_container noprint">
        <div class="footer">
          <div>© 2002-{{ year }}</div>
          <div class="desktop_only">|</div>
          <div>The Jellyvision Lab, Inc.</div>
          <div class="desktop_only">|</div>
          <div>All rights reserved.</div>
          <div class="desktop_only">|</div>
          <div>
            <a
              href="https://www.jellyvision.com/privacy-policy/"
              target="_blank"
              >Privacy Policy</a
            >
          </div>
        </div>
      </div>
      <FeedbackModal
        :questions="feedbackQuestions"
        thankYouMessage="Thanks for helping!"
      ></FeedbackModal>
    </div>
  </div>
</template>
<script>
import LoaFrontPage from "@/components/shared/LOA/LoaFrontPage";
import LoaSurveyIntro from "@/components/shared/LOA/LoaSurveyIntro";
import Survey3 from "@/components/shared/Survey/Survey3";
import LoaContactModal from "@/components/shared/LOA/LoaContactModal";
import LoaInformationPage from "@/components/shared/LOA/LoaInformationPage";
import { useLoaStore } from "@/stores/loa";
import LoaRedirectPage from "@/components/shared/LOA/LoaRedirectPage";
import FeedbackModal from "@/components/shared/FeedbackModal";
import { data } from "@/shared";
require("@/assets/css/shared/checklist/checklist_variables_standard.scss");
require("@/assets/css/shared/sample_language/sample_language_variables_standard.scss");
require("@/assets/css/shared/feedback/feedback_variables_standard.scss");
require("@/assets/css/shared/send_email/send_email_variables_standard.scss");
export default {
  components: {
    LoaFrontPage,
    LoaSurveyIntro,
    LoaContactModal,
    LoaInformationPage,
    LoaRedirectPage,
    FeedbackModal,
    Survey3,
  },
  name: "LeaveOfAbsence",
  computed: {
    year() {
      return new Date().getFullYear();
    },
    getSubHeaderStyle() {
      if (this.page === 3 && this.activeInfoPageConfig) {
        if (this.activeInfoPageConfig.sectionTitleColor) {
          return (
            "background-color:" + this.activeInfoPageConfig.sectionTitleColor
          );
        }
      }

      return "";
    },
    subHeaderClasses() {
      var classes = "";

      if (this.page === 0) classes = classes + " subheader_with_text";
      else classes = classes + " subheader_empty";

      if (this.page !== 3) classes = classes + " subheader_blue";

      return classes;
    },
    getArrowClass() {
      if (this.page !== 3) return "arrow_down_blue";
      else return "";
    },
    getArrowColor() {
      if (this.page === 3 && this.activeInfoPageConfig) {
        if (this.activeInfoPageConfig.sectionTitleColor)
          return (
            "border-top-color: " + this.activeInfoPageConfig.sectionTitleColor
          );
      }

      return "";
    },
    emailTemplate() {
      if (this.loaConfiguration.emailTemplate)
        return this.loaConfiguration.emailTemplate;
      else return "loaresultsemail";
    },
    contactModalButtonText() {
      return (
        this.loaConfiguration.contactModalButtonText ??
        "Contact Your Leave Team"
      );
    },
  },
  data() {
    return {
      page: -1,
      i: 0,
      showModal: false,
      surveyCode: null,
      activeInfoPageConfig: null,
      activeRedirectText: null,
      replacements: {},
      loaConfiguration: {},
      emailOpenModalText_multiLang: undefined,
      feedbackQuestions: [
        {
          question: "How helpful has this experience been?",
          subject: "HOW_HELPFUL",
          sequence: 1,
          icons: [
            { score: 1, class: "far fa-grimace", text: "Not at all helpful" },
            { score: 2, class: "far fa-frown", text: "Not very helpful" },
            { score: 3, class: "far fa-meh", text: "Somewhat helpful" },
            { score: 4, class: "far fa-smile", text: "Very helpful" },
            { score: 5, class: "far fa-grin-stars", text: "Extremely helpful" },
          ],
        },
        {
          question:
            "Do you feel like you have a better understanding of how a Leave of Absence works now?",
          sequence: 2,
          subject: "BETTER_UNDRSTNDG",
          icons: [
            { score: 1, class: "far fa-grimace", text: "No, definitely not" },
            { score: 2, class: "far fa-frown", text: "No, not really" },
            { score: 3, class: "far fa-meh", text: "Maybe" },
            { score: 4, class: "far fa-smile", text: "Yes, a little" },
            { score: 5, class: "far fa-grin-stars", text: "Yes, definitely" },
          ],
        },
      ],
    };
  },
  props: {
    onQuestionAnswered: {
      type: Function,
    },
    clientCode: {
      type: String,
      default: "",
    },
    frontPageRightContent: null,
    introPageRightContent: null,
    backText: null
  },
  setup() {
    const piniaSurvey = useLoaStore();
    return {
      piniaSurvey,
    };
  },
  async mounted() {
    document.body.classList.add("loa_body");
    var fileStorageUrl = await data.getEnvironmentVariable("fileStorageUrl");
    if(fileStorageUrl.charAt(fileStorageUrl.length - 1) != '/')
      fileStorageUrl = fileStorageUrl + '/';


    //handle this for now simply....we need to do make this better after Carnival goes live
    if(this.frontPageRightContent?.img) {
      this.loaConfiguration.frontPageRightContent = {
        ...this.frontPageRightContent, 
        img: {              
          ...this.frontPageRightContent.img, 
          src: (!this.frontPageRightContent.img.configuration.isLocalPath ? fileStorageUrl : '') + 
            this.frontPageRightContent.img.configuration.src
        }
      };
    }

    if(this.introPageRightContent?.img) {
      this.loaConfiguration.introPageRightContent = {
        ...this.introPageRightContent, 
        img: {              
          ...this.introPageRightContent.img, 
          src: (!this.introPageRightContent.img.configuration.isLocalPath ? fileStorageUrl : '') + 
            this.introPageRightContent.img.configuration.src
        }
      };
    }

    data.getClientLogoAssetID().then((clientLogoAssetID) => {
      if (clientLogoAssetID > 0) {
        data.getMediaProperty(clientLogoAssetID).then((clientLogoAsset) => {
          if (clientLogoAsset)
            this.loaConfiguration.webPageLogoPath =
              (!clientLogoAsset.configuration.isLocalPath ? fileStorageUrl : "") +
              clientLogoAsset.configuration.src;
        });
      }
    });

    data.getPageProperties().then((result) => {
      if (
        result.loaProperties &&
        result.loaProperties.managerAccordionConfiguration &&
        result.loaProperties.managerAccordionConfiguration.accordionCode != ""
      ) {
        this.loaConfiguration.managerPathConfig =
          result.loaProperties.managerAccordionConfiguration;
      }

      if (result.emailTemplateMediaPropertyId > 0) {
        data
          .getMediaProperty(result.emailTemplateMediaPropertyId)
          .then((emailResult) => {
            this.loaConfiguration.emailTemplate =
              emailResult.configuration.templateName + "";
            for (
              let i = 0;
              i < emailResult.configuration.openModalTextJson.length;
              i++
            ) {
              if (emailResult.configuration.openModalTextJson[i].text) {
                this.emailOpenModalText_multiLang =
                  emailResult.configuration.openModalTextJson;
                break;
              }
            }

            if (emailResult.configuration.logoImageMediaPropertyId > 0) {
              data
                .getMediaProperty(
                  emailResult.configuration.logoImageMediaPropertyId
                )
                .then((logoResult) => {
                  this.loaConfiguration.emailCorporateLogoPath =
                    logoResult.configuration.src;
                });
            }
          });
      }
    });

    await this.GetClientConstants();

    data.postActivityLog({
      clientCode: this.clientCode,
      logActivityTypeCode: "LANDING_PAGE",
    });

    this.page = 0;
  },
  unmounted() {
    document.body.classList.remove("loa_body");
  },
  methods: {
    local_onEndOfSurvey: async function(action) {
      this.getRelacementStrings();

      if (action.subType === "AccordionInformationPage")
        this.activeInfoPageConfig = action.configuration;
      else if (action.subType === "SimpleInformationPage")
        this.activeRedirectText = action.configuration.displayText;

      this.page++;
    },
    getRelacementStrings() {
      this.replacements = this.piniaSurvey.getReplacementStrings;
      var client_replacements;

      if (client_replacements == null) client_replacements = {};
      Object.entries(client_replacements).forEach((entry) => {
        this.replacements[entry[0]] = entry[1];
      });
    },
    ShowContactModal: async function() {
      data.postActivityLog({
        clientCode: this.clientCode,
        logActivityTypeCode: "CLICK_LEAVE_MGMT",
      });
      this.showModal = true;
    },
    closeModal: function() {
      this.showModal = false;
    },
    onManagerClick: function() {
    this.loaConfiguration.managerPathConfig.className = 'manager';
      this.activeInfoPageConfig = this.loaConfiguration.managerPathConfig;
      this.activeRedirectText = null;

      var logObject = {
        survey_action_key: null,
        action_type: "ShortCirct",
        action_sub_type: "AccordionInformationPage",
        accordion: this.loaConfiguration.managerPathConfig.accordionCode,
      };

      data.postActivityLog({
        logActivityTypeCode: "SURVEY_ACTION",
        sourceType: "IPAddress",
        data: JSON.stringify(logObject),
      });

      this.getRelacementStrings();
      this.page = 3;
    },
    getStarted: function() {
      data.postActivityLog({
        clientCode: this.clientCode,
        logActivityTypeCode: "CLICK_GET_STARTED",
      });
      this.nextPage();
    },
    goToSurvey: function() {
      data.postActivityLog({
        clientCode: this.clientCode,
        logActivityTypeCode: "CLICK_ELIGIBILITY",
      });
      this.nextPage();
    },
    nextPage: function() {
      this.page++;
    },
    previousPage: function() {
      this.page--;
    },
    StartOver: function() {
      this.page = 0;
      this.activeInfoPageConfig = null;
      this.activeRedirectText = null;
      this.piniaSurvey.setAttributes(null);
    },
    GetClientConstants: async function() {
      const constants = await data.getClientConstants();

      //get attributes as replacement characters
      var replacementAttributes = await data.getReplacementStringAttributes();
      var replacementStrings = {};

      if (constants != null) {
        constants.forEach((constant) => {
          switch (constant.key) {
            case "LOA_FINE_PRINT_1":
              this.loaConfiguration.finePrintColumn1 = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_FINE_PRINT_2":
              this.loaConfiguration.finePrintColumn2 = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_FINE_PRINT_3":
              this.loaConfiguration.finePrintColumn3 = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_FRONT_PAGE_TITLE":
              this.loaConfiguration.frontPageTitle = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_FRONT_PAGE_SUBTITLE":
              this.loaConfiguration.frontPageSubtitle = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_FRONT_PAGE_MANAGER_TEXT":
              this.loaConfiguration.managerText = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_FRONT_PAGE_TEXT":
              this.loaConfiguration.frontPageMainText = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_CONTACT_MODAL_MESSAGE":
              this.loaConfiguration.contactModalMessage = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_CONTACT_MODAL_TITLE":
              this.loaConfiguration.contactModalTitle = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_CONTACT_MODAL_FOOTER":
              this.loaConfiguration.contactModalFooter = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_CONTACT_MODAL_CUSTOM_CONTACT_INFO":
              this.loaConfiguration.contactModalCustomContactInfo = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "HR_PHONE_NUMBER":
              this.loaConfiguration.contactModalPhoneNumber = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "HR_EMAIL_ADDRESS":
              this.loaConfiguration.contactModalEmailAddress = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_CONTACTMODAL_BUTTON_TEXT":
              this.loaConfiguration.contactModalButtonText = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_SURVEY_INTRO_TEXT":
              this.loaConfiguration.surveyIntroText = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_SURVEY_INTRO_TITLE":
              this.loaConfiguration.surveyIntroTitle = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            case "LOA_SURVEY_CODE":
              this.surveyCode = constant.valueJson.find(
                (val) => val.language === "English"
              ).text;
              break;
            //     case "LOA_MANAGER_ACCORDION_CODE":
            //         this.loaConfiguration.managerPathConfig.accordionCode = constant.value;
            //         break;
            default:
              break;
          }

          if (
            replacementAttributes.find((search) => search.code === constant.key)
          )
            replacementStrings[
              "%" + constant.key + "%"
            ] = constant.valueJson.find(
              (val) => val.language === "English"
            ).text;
        });
      }

      replacementStrings[
        "%FILE_STORAGE_URL%"
      ] = await data.getEnvironmentVariable("fileStorageUrl");
      this.piniaSurvey.setReplacementStrings(replacementStrings);

      this.i++;
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");
</style>
<style scoped>
.logo img {
  margin: -1.875em 0px;
}

.subheader_empty {
  height: 1.875em;
}

.subheader_with_text {
  height: 5em;
}

.subheader_blue {
  background-color: #0981b0;
}
.subheader_green {
  background-color: #3cbf00;
}

.subheader_teal {
  background-color: #048468;
}

.arrow_down_teal {
  border-top: 0.75em solid #048468;
}

.visible {
  display: unset;
}

.hidden {
  display: none;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 0.656em solid transparent;
  border-right: 0.656em solid transparent;
  border-top: 0.75em solid transparent;
  margin-left: 12.5em;
}
@media only screen and (max-width: 962px) {
  .arrow-down {
    margin-left: 1.875em;
  }
}
.left-of-arrow {
  width: 8.75em;
  height: 0.75em;
  position: absolute;
  left: 0;
  background-color: rgb(47, 47, 82);
}
.right-of-arrow {
  width: 8.75em;
  height: 0.75em;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgb(47, 47, 82);
}

.arrow_down_purple {
  border-top: 0.75em solid #882f7d;
}

.arrow_down_blue {
  border-top: 0.75em solid #0981b0;
}
.arrow_down_green {
  border-top: 0.75em solid #3cbf00;
}

.flex_across {
  display: flex;
  flex: 1 1 auto;
}
</style>
