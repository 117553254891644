<template>
    <div class="sendEmailContainer">
        <div class="sendEmailButton">
            <span @click="openModal"><i v-if="showEmailIcon" class="fa-regular fa-envelope"></i> {{ openModalButtonText }}</span>
        </div>
        <div v-if="modalActive" class="sendEmailModalBackdrop" v-on:click="closeModal"></div>
        <div class="sendEmailModal" v-if="modalActive">
            <div class="sendEmailModalClose" v-on:click="closeModal">-</div>
            <div class="sendEmailRow" v-if="hasSender">
                <label>Sender</label>
                <input class="senderInput" type="text" :model="email.sender" />
            </div>
            <div class="sendEmailRow" v-if="hasRecipients">
                <label v-if="type === 'PrintPage'">{{ enterEmailText }}</label>
                <input v-if="type === 'PrintPage'" type="text" v-model="email.singleRecipient" />
                <label v-if="type !== 'PrintPage'">Recipients (add each address on a new line)</label>
                <textarea v-if="type !== 'PrintPage'" class="recipientsTextBox" :model="email.recipientsString"></textarea>
            </div>
            <div class="sendEmailRow" v-if="hasSubject">
                <label>Subject</label>
                <input type="text" :model="email.subject" />
            </div>
            <div class="sendEmailRow" v-if="hasBody">
                <label>Email Text</label>
                <textarea :model="email.bodyText"></textarea>
            </div>
            <div class="sendEmailRow" v-if="hasBody">
                <label>Email HTML</label>
                <textarea :model="email.bodyHtml"></textArea>
            </div>
            <div class="sendEmailRow" v-if="hasAttachment">
                <input type="file" name="email_attachment" @change="addAttachment()" hidden>
                <label v-if="!attachment" for="email_attachment" class="fileInput">Upload a File</label>
                <label v-if="attachment" for="email_attachment" class="fileInput">Upload a Different File</label>
                <img v-if="attachment" :src="attachment" class="attachmentPreview" :key="attachmentPreviewKey" />
            </div>
            <div class="sendEmailRow">
                <div class="sendEmailButtonContainer">
                    <button @click="sendEmail" v-if="!generating && !sent">{{ sendEmailText }}</button>
                    <button disabled v-if="generating">{{ generatingText }}</button>
                    <button disabled v-if="sent">{{ emailSentText }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { data } from '../../shared';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {translation} from '@/shared/translation';

export default {
    name: 'SendEmailModal',
    props: {
        // Types: Template, Custom, PrintPage
        type: String,
        elementId: String,
        sender: String,
        subject: String,
        attachmentName: String,
        templateVariables: Object,
        template: String,
        templateType: String,
        inlineImages: Array,
        openModalText_multiLang: {
            // [{language: 'English', text: 'Open modal'}]
            type: Array,
            default: () => [{language: 'English', text: 'Send Results As Email'}]
        },
        enterEmailText: {
            type: String,
            default: 'Enter your Email Address'
        },
        sendEmailText: {
            type: String,
            default: 'SEND EMAIL'
        },
        generatingText: {
            type: String,
            default: 'GENERATING PDF'
        },
        emailSentText: {
            type: String,
            default: 'EMAIL SENT!'
        },
        showEmailIcon: {
            type: Boolean,
            default: true
        },
        language: {
            type: String,
            default: 'English'
        }
    },
    data() {
        return {
            reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, 
            modalActive: false,
            generating: false,
            sent: false,
            recipientsString: '',
            email: {
                sender: '',
                recipients: [],
                singleRecipient: '',
                subject: '',
                text: '',
                html: '',
                attachments: [],
                templateVariables: '',
                template: '',
                templateType: null,
                inlineImages: []
            }
        }
    },
    computed: {
        openModalButtonText() {
            return translation.getTextForLanguage(this.openModalText_multiLang, this.language);
        },
        hasSender() {
            if(this.type === 'Template' || this.type == 'Custom' || this.type == 'Raw')
                return true;
            else
                return false;
        },
        hasRecipients() {
            return true;
        },
        hasSubject() {
            if(this.type === 'Template' || this.type == 'Custom' || this.type == 'Raw')
                return true;
            else
                return false;
        },
        hasBody() {
            if(this.type === 'Template' || this.type == 'Custom' || this.type == 'Raw')
                return true;
            else
                return false;
        },
        hasAttachment() {
            if(this.type == 'Raw')
                return true;
            else
                return false;
        }
    },
    methods: {
        openModal: function() {
            this.modalActive = true;
        },
        closeModal: function() {
            this.modalActive = false;
            this.generating = false;
            this.sent = false;
            this.resetEmail();
            this.email.singleRecipient = '';
        },
        async beforeDownload({html2pdf, options, pdfContent}) {
            await html2pdf().set(options).from(pdfContent).toPdf().output('datauristring').then((pdfAsString) => {
                this.attachment = pdfAsString;
            })
        },
        resetEmail: function() {
            this.email.recipients = [];
            this.email.attachments = [];
            this.email.inlineImages = [];
        },
        sendEmail: async function() {
            this.resetEmail();

            if(this.email.singleRecipient)
                this.email.recipients.push(this.email.singleRecipient);
            else
                this.email.recipients = this.recipientsString.split(/[\r\n]+/).map((str) => {return str.trim()});
            for(let i = 0; i < this.email.recipients.length; i++) {
                if(!this.reg.test(this.email.recipients[i])) {
                    this.$toast.error("Invalid email address");
                    this.email.recipients = [];
                    return;
                }
            }
            this.generating = true;
            if(this.type === 'PrintPage') {
                let pdfBlob = await this.generatePdf();
                this.email.attachments.push(new File([pdfBlob], this.attachmentName));
            }
            if(this.sender) {
                this.email.sender = this.sender;
            }
            if(this.email.sender.indexOf('<') >= 0) {
                let email = this.email.sender.split('<')[1].slice(0, -1);
                if(!this.reg.test(email)){
                    this.$toast.error("Invalid email address");
                    return;
                }
            } else {
                if(!this.reg.test(this.email.sender)){
                    this.$toast.error("Invalid email address");
                    return;
                }
            }
            if(this.subject)
                this.email.subject = this.subject;

            if(this.template)
                this.email.template = this.template;

            if(this.templateType)
                this.email.templateType = this.templateType;
            
            if(this.templateVariables)
                this.email.templateVariablesJson = JSON.stringify(this.templateVariables);
            
            if(this.inlineImages) {
                for(let i = 0; i < this.inlineImages.length; i++) {
                    await fetch(this.inlineImages[i].url)
                            .then(response => response.blob())
                            .then(imageBlob => {
                                this.email.inlineImages.push(new File([imageBlob], this.inlineImages[i].name));
                            })
                }
            }

            let resp = await data.postEmail(this.email);
            if (resp?.id) {
                this.generating = false;
                this.sent = true;
                let closeModal = this.closeModal;
                setTimeout(function() {
                    closeModal();
                }, 3000);
            } else {
                this.generating = false;
                this.$toast.error("Email failed to send",{
                    style: {
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }
                } );
            }
        },
        generatePdf: async function() {
            const dom = document.getElementById(this.elementId);
            const elemId = this.elementId;
            let canvas = await html2canvas(dom, {
                onclone: function(element) {
                    if(elemId === 'infoAccordion') {
                        let dds = element.getElementsByTagName('dd');
                        for (let i = 0; i < dds.length; i++) {
                            dds[i].style.height = 'auto';                    
                        }
                    }
                }
            });
            const margin = 5;
            const imgWidth = 210;
            const pageHeight = 280;
            const imgHeight = ((canvas.height * imgWidth) / canvas.width);
            let heightLeft = imgHeight;
            let position = 0;
            
            const doc = new jsPDF('p', 'mm', 'letter', false, true);
            doc.addImage(canvas, 'JPEG', margin, position, imgWidth - margin, imgHeight, '', 'FAST');
            heightLeft -= pageHeight;
            while(heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(canvas, 'JPEG', margin, position, imgWidth - margin, imgHeight, '', 'FAST');
                heightLeft -= pageHeight;
            }
            return new Blob([doc.output('blob')], {type: 'application/pdf'});
        }
    }
}
</script>
