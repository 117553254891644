<template >
    <div class="modalBackdrop" v-on:click="onClose">
    <transition name="modalTransition">
        <div class="modalContainer" @click.stop="">
            <div class="modalContent">
                <div class="title">{{modalTitle}}</div>
                <div class="text">{{modalText}}</div>
                <div v-if="phoneNumber && !customContactInfo" class="phone">
                    <i class="fas fa-phone"></i>
                    <div class="phoneText">
                        We can be reached during local office hours at: <span>{{phoneNumber}}.</span>
                    </div>
                </div>
                <div v-if="emailAddress && !customContactInfo" class="email">
                    <i class="fas fa-envelope"></i>
                    <div class="emailText">
                        We can be reached anytime at <a href="mailto:{{emailAddress}}?subject=I have a question about a Leave of Absence">{{emailAddress}}</a>
                    </div>
                </div>
                <div v-if="customContactInfo" v-html="customContactInfo"></div>
                <div class="takeCare">{{modalFooter}}</div>
            </div>
            <div class="modalClose" v-on:click="onClose">-</div>
        </div>
    </transition>
    </div>
</template>

<script>
export default {
    name: "LoaContactModal",
    props: {
        onClose : {
            type: Function
        },
        modalText: {
            type: String,
            default: "If you have a specific question regarding your leave options, or if you just want to talk through the process, we’re here for you."
        },
        modalTitle: {
            type: String,
            default: "Contact your Leave Team"
        }, 
        modalFooter: {
            type: String,
            default: "Take care"
        },
        customContactInfo: {
            type: String, 
            default: null
        },
        phoneNumber: {
            type: String,
            default: null
        },
        emailAddress: {
            type: String,
            default: null
        }
   } ,
    data() {
        return {
            componentKey: 0,
     };
    }
}
</script>
