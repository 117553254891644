<template>
    <span class="tooltip-container">
        <span class="tooltip-slot-container" ref="tooltipContainer" @mouseover="showTooltip" @mouseleave="hideTooltip">
            <slot name="hoverText" />
            <slot name="hoverIcon"><i class="fas fa-question-circle tooltip-icon"></i></slot>
        </span>
        <div class="tooltip-content-container" ref="tooltip" style="visibility: hidden; opacity: 0" >
          <div class="tooltip-content hidden" ref="tooltipContent">
            <slot name="content" />
          </div>
        </div>
    </span>
</template>
<script>
export default {
  name: 'ToolTip',
  props: { 
    configuration: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      hover: false
    };
  },
  methods: {
    showTooltip: async function() {
      if(this.hover == true) return;
      this.hover = true;

   //   this.$refs.tooltipContent.classList.add('hidden');
  //    this.$refs.tooltip.style.visibility = 'hidden';
 //     this.$refs.tooltip.style.opacity = 0; // Make sure it's not visible during positioning
        
      await this.repositionTooltip();

      this.$refs.tooltip.style.visibility = 'visible';
      this.$refs.tooltip.style.opacity = 1; // Make sure it's not visible during positioning

      // Use a small delay to ensure visibility and positioning are applied before removing the hidden class
      setTimeout(() => {
        this.$refs.tooltipContent.classList.remove('hidden');  // Remove the hidden class after positioning
      }, 50); // 50ms delay, to ensure show animation occurs

    },
    hideTooltip: async function() {
      this.hover = false;

      const tooltipContent = this.$refs.tooltipContent;
      const computedStyle = window.getComputedStyle(tooltipContent);

      // Check if there is a transition property
      const transitionDuration = computedStyle.transitionDuration;

      // If transitionDuration is set and transitionProperty includes 'transform', we know the duration of the transition
      if (transitionDuration) {
        const transitionTimeInMs = this.convertTimeToMilliseconds(transitionDuration);
        
        tooltipContent.classList.add('hidden');  // Add the 'hidden' class to hide
        setTimeout(() => {
          // Hide the tooltip after the transition completes
          this.$refs.tooltip.style.visibility = 'hidden'; // Ensure visibility is hidden
          this.$refs.tooltip.style.display = 'none';  // Remove from layout
        }, transitionTimeInMs);
      } else {
        // No transition or transform, hide immediately
        tooltipContent.classList.add('hidden');
        this.$refs.tooltip.style.visibility = 'hidden';
        this.$refs.tooltip.style.display = 'none';
      }
    },
    convertTimeToMilliseconds(time) {
      if (time.includes('ms')) {
        return parseFloat(time); // It's already in milliseconds
      } else if (time.includes('s')) {
        return parseFloat(time) * 1000; // Convert seconds to milliseconds
      }
      return 0; // Return 0 if time format is unexpected
    },
    repositionTooltip: async function() {
      if(!this.hover) return;

      const tooltipContainer = this.$refs.tooltipContainer;
      const tooltip = this.$refs.tooltip;
      
      const rect = tooltipContainer.getBoundingClientRect();

      // Temporarily make tooltip visible for measurement
      tooltip.style.position = 'absolute'; // Set to absolute to take it off-screen
      tooltip.style.left = '-9999px'; // Move the tooltip off-screen
      tooltip.style.display = 'block'; // Show tooltip briefly to calculate dimensions
      await new Promise(resolve => setTimeout(resolve, 0)); // Allow time for styles to be applied

      // Get the tooltip dimensions while it's visible
      const tooltipRect = tooltip.getBoundingClientRect();

      const tooltipRectWidth = tooltipRect.width;
      const tooltipRectHeight = tooltipRect.height;

      // Hide the tooltip again to prevent it from showing during repositioning
      tooltip.style.display = 'none'; // Hide it again
      tooltip.style.position = 'fixed'; // Set to absolute to take it off-screen
    
      var absolutePosition = await this.calculatePosition();

      var top = absolutePosition.top - tooltipRectHeight;
      var v_middle = ((rect.top + rect.bottom) / 2)  - (tooltipRectHeight / 2);
      var bottom = absolutePosition.top + rect.height;
      
      var left = absolutePosition.left - tooltipRectWidth;
      var h_middle= absolutePosition.left + (rect.width/2) - (tooltipRectWidth /2);
      var right = absolutePosition.left + rect.width;
      var alignments = ['start', 'middle', 'end'];

      var positions= {};
      positions["top-middle"] = {
        top: top,
        left: h_middle,
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["top-start"] = {
        top: top,
        left: left,
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["top-end"] = {
        top: top,
        left: right,
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["bottom-middle"] = {
        top: bottom,
        left: h_middle,
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["bottom-start"] = {
        top: bottom,
        left: left,
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["bottom-end"] = {
        top: bottom,
        left: right , 
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["left-middle"] = {
        top: v_middle, 
        left: left ,
        alternatePositions: ['left', 'right', 'top', 'bottom']
      };


      positions["left-start"] = {
        top: top, 
        left: left ,
        alternatePositions: ['left', 'right', 'top', 'bottom']
      };


      positions["left-end"] = {
        top: bottom, 
        left: left, 
        alternatePositions: ['left', 'right', 'top', 'bottom']
      };

      positions["right-middle"] = {
        top: v_middle,
        left: right, 
        alternatePositions: ['right', 'left', 'top', 'bottom']
      };

      positions["right-start"] = {
        top: top,
        left: right,
        alternatePositions: ['right', 'left', 'top', 'bottom']
      };

      positions["right-end"] = {
        top: bottom,
        left: right,
        alternatePositions: ['right', 'left', 'top', 'bottom']
      };

      Object.keys(positions).forEach(key => {
        positions[key].right = positions[key].left + tooltipRectWidth;
        positions[key].bottom = positions[key].top + tooltipRectHeight;
        positions[key].isValidTop = positions[key].top > 0;
        positions[key].isValidBottom = positions[key].bottom < window.innerHeight;
        positions[key].isValidLeft = positions[key].left > 0;
        positions[key].isValidRight = positions[key].right < window.innerWidth;
        positions[key].isValid = positions[key].isValidTop && positions[key].isValidLeft && positions[key].isValidRight && positions[key].isValidBottom;
      });

      var pref_pos_and_align = this.configuration.position + '-' + this.configuration.alignment;
      var preferredPosition = positions[pref_pos_and_align];
      if(preferredPosition.isValid === false && this.configuration.useSmartRepositioning === true) {
        for(const pos of preferredPosition.alternatePositions) {
            for(var i = 0; i < alignments.length; i++)
            {
              var position = pos + '-' + alignments[i];
              if(position === pref_pos_and_align || positions[position] == null) continue;

              if(positions[position].isValid) {
                    preferredPosition = positions[position];
                    break;
              }
            }         
        }
      }

      tooltip.style.left = preferredPosition.left + 'px';
      tooltip.style.top = preferredPosition.top + 'px';
      tooltip.style.display = 'block';
    },
    calculatePosition: async function () {
      const child = this.$refs.tooltipContainer;
      const rect = child.getBoundingClientRect();  // Get the position relative to the viewport

      // Check if the tooltip's parent is scrollable (either overflowY or overflowX)
      let parent = child.offsetParent;  // The closest positioned ancestor (scrollable container)
      let isScrollable = false;

      let scrollOffsetTop = 0;
      let scrollOffsetLeft = 0;

      while (parent) {
        const overflowY = window.getComputedStyle(parent).overflowY;
        const overflowX = window.getComputedStyle(parent).overflowX;

        // Check for vertical scrolling
        if (overflowY === 'auto' || overflowY === 'scroll') {
          isScrollable = true;
          scrollOffsetTop = parent.scrollTop;  // Adjust for vertical scroll
        }

        // Check for horizontal scrolling
        if (overflowX === 'auto' || overflowX === 'scroll') {
          isScrollable = true;
          scrollOffsetLeft = parent.scrollLeft;  // Adjust for horizontal scroll
        }

        parent = parent.offsetParent;
      }

      if (isScrollable) {
        // If inside a scrollable container, adjust the position based on the scroll
        const parentRect = parent ? parent.getBoundingClientRect() : { top: 0, left: 0 };  // Get parent position

        return {
          top: rect.top - parentRect.top + scrollOffsetTop,  // Adjust for vertical scroll (if applicable)
          left: rect.left - parentRect.left + scrollOffsetLeft  // Adjust for horizontal scroll (if applicable)
        };
      } else {
        // If outside of a scrollable container, just return the fixed position relative to the viewport
        return {
          top: rect.top,
          left: rect.left
        };
      }
    }
  },
};
</script>
<style scoped>
  .tooltip-container { 
    position: relative;
    display: inline-block;
  }

  .tooltip-slot-container {
      display:flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
  }

  .tooltip-icon {
      padding-left: .2em;
      font-size: 1em;
  }

 .tooltip-content-container {
      position: fixed;
      z-index: 100;
      box-sizing: border-box;
 }
</style>
